import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { VscCopy } from "react-icons/vsc";

type TextObject = {
  id: string;
  value: string;
};

type TextCopyProps = string | TextObject;

interface TextCopyComponentProps {
  text: TextCopyProps;
}

const TextCopy: React.FC<TextCopyComponentProps> = ({ text }) => {
  const { t } = useTranslation("dashboard");

  const [copied, setCopied] = useState<string | null>(null);
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const value = typeof text === "string" ? text : text.value;
  const id = typeof text === "object" ? text.id : undefined;

  if (!value) return <span>N/A</span>;

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setCopied(id || value);

    setTimeout(() => {
      if (isMounted.current) {
        setCopied(null);
      }
    }, 2000);
  };

  const divStyle = {
    "& .link-cell": {
      display: "flex",
      gap: "0.25rem",
      alignItems: "center",
      cursor: "pointer",
      padding: "0.75rem 0",
      transition: "transform 0.4s",
      "&.copied": {
        color: "#0ab575",
      },
      "&:hover": {
        color: "#0ab575",
        transform: "translateY(-4px)",
      },
    },
  };

  return (
    <Box sx={divStyle} title={value}>
      <Box
        className={copied === (id || value) ? "copied link-cell" : "link-cell"}
        onClick={handleCopy}
      >
        {copied === (id || value)
          ? `${t("copied")}!`
          : `${value.slice(0, 5)}...`}
        <VscCopy size={16} />
      </Box>
    </Box>
  );
};

export default TextCopy;
